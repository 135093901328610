export default {
    name: "document",
    created() {
        window.getRicToDetails = this.getRicToDetails;
    },
    mounted() {
        this.$nextTick(function () {
            this.gatCorporateActionData();
            this.GetDeployedBaseProspectusData();
            this.getRicList();
            this.arrowsAhidden('table_box1')
            this.headAhidde('table_box1')
        });
    },
    data() {
        return {
            TermSheetsData: [],
            /*保存数据*/
            CorporateActionsData: [],
            ric_data: [],
            ifFocus: false
        };
    },
    methods: {
        getRicList() {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetRICList",
                async: false,
                data: { token: "webkey" },
                success: (data) => {
                    if (data.status == "SUCCESS") {
                        this.ric_data = data.ric;
                        this.GetDeployedTermSheetData();
                    }
                }
            })
        },
        GetDeployedBaseProspectusData() {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetDeployedBaseProspectus",
                data: { token: "webkey", lang: window.sessionStorage.getItem('lang') },
                success: (response) => {
                    if (response.status == 'SUCCESS') {
                        this.baseProspectusAppend(response.text)
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        GetDeployedTermSheetData() {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetDeployedTermSheet",
                data: { token: "webkey" },
                success: (response) => {
                    if (response.status == 'SUCCESS') {
                        this.termSheetsAppend(response.text)
                        this.TermSheetsData = response.text;
                        //TermSheets筛选赋值
                        var html = '';
                        $.each(response.text, (index, comment) => {
                            var val = comment['dsply_nmll'] + ' (' + comment['ticker'] + ')';
                            html = '<div class="dropdown-item  text_center">' + val + '</div>';
                            $("#menu").append(html);
                            this.ricSelectOption(index, comment, val); /*显示当前选择项*/
                        });
                        //input监控
                        this.searchSymbol(response.text);
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        gatCorporateActionData() {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetDeployedCorporateAction",
                data: { token: "webkey", lang: window.sessionStorage.getItem('lang') },
                success: (response) => {
                    if (response.status == 'SUCCESS') {
                        var aThead = response.text[0]
                        for (var h = 0, max = Object.keys(aThead).length; h < max; h++) {
                            $.each(aThead, (v) => {
                                if (v.replace(/[^0-9]/ig, "") == (h + 1)) {

                                    this.corporateActionsAppendThead(aThead[v], h);
                                }
                            })
                        }
                        var atbody = response.text[1];
                        var arr = []
                        $.each(atbody, (index, value) => {
                            arr[index] = []
                            $.each(value, (v, c) => {
                                if (v.indexOf(v.replace(/[^0-9]/ig, "")) > 0) {
                                    if (value[v] == "TBD") {
                                        arr[index][v.replace(/[^0-9]/ig, "") - 1] = "TBD";
                                    } else if (v.replace(/[^0-9]/ig, "") - 1 === 2 || v.replace(/[^0-9]/ig, "") - 1 === 4) {
                                        arr[index][v.replace(/[^0-9]/ig, "") - 1] = this.$js.formatNumber(value[v], 4, 0);
                                    } else if (v.replace(/[^0-9]/ig, "") - 1 === 3 || v.replace(/[^0-9]/ig, "") - 1 === 5) {
                                        arr[index][v.replace(/[^0-9]/ig, "") - 1] = this.$js.curreryRetrun(value[v].split(" ")[0]) + " " + this.$js.formatNumber(value[v].split(" ")[1], 0, 0);
                                    } else {
                                        arr[index][v.replace(/[^0-9]/ig, "") - 1] = value[v];
                                    }
                                }
                            })
                            var len = Object.keys(aThead).length - arr[index].length
                            for (var j = 0, max1 = len; j < max1; j++) {
                                if (value.hasOwnProperty("file_path")) {
                                    arr[index][j + arr[index].length] = value.file_path
                                } else {
                                    arr[index][j + arr[index].length] = 'N/A'
                                }
                            }
                        })
                        this.CorporateActionsData = arr
                        this.corporateActionsAppendTbody(this.CorporateActionsData);
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        baseProspectusAppend(data) {
            //console.log(data)
            var htmlStr = "";
            var type = 'prospectus'
            for (var i = 0; i < data.length; i++) {
                htmlStr += "<tr>";
                htmlStr += "<td style='width: 15%' class='text-center'>" + (i + 1) + "</td>";
                htmlStr += "<td>" + data[i].file_name + "</td>";
                htmlStr += "<td style='width: 20%' class='text-center'>" + this.$js.formatDate(data[i].update_date) + "</td>";
                htmlStr += "<td class='text-center'><a href='" + data[i].file_path + "' target='_blank'><picture><source srcset='/image/pdf.webp' type='image/webp'><img src='/image/pdf.png' alt=''></picture></a></td>";
                htmlStr += "</tr>";
            }
            $("#baseProspectus").empty();
            $("#baseProspectus").append(htmlStr);
        },
        termSheetsAppend(data) {
            var htmlStr = "";
            var type = 'termsheet'
            for (var i = 0; i < data.length; i++) {
                var isRicExist = false;
                var theRic = '';
                $.each(this.ric_data, function (index, datas) {
                    if (datas.dsply_nmll == data[i].dsply_nmll) {
                        isRicExist = true;
                        theRic = datas.ric;
                    }
                });
                htmlStr += "<tr>";
                if (isRicExist) {
                    htmlStr += "<td class='text-center'><a href='javascript:getRicToDetails(\"" + theRic + "\"," + " \"" + data[i].dsply_nmll + "\")'>" + data[i].ticker + "</a></td>";
                    htmlStr += "<td class='text-center'><a href='javascript:getRicToDetails(\"" + theRic + "\"," + " \"" + data[i].dsply_nmll + "\")'>" + data[i].dsply_nmll + "</a></td>";
                } else {
                    htmlStr += "<td class='text-center'>" + data[i].ticker + "</td>";
                    htmlStr += "<td class='text-center'>" + data[i].dsply_nmll + "</td>";
                }
                htmlStr += "<td class='text-center'>" + this.$js.formatDate(data[i].maturity) + "</td>";
                htmlStr += "<td class='text-center'>" + data[i].exercise_price + "</td>";
                if (data[i].file_path) {
                    htmlStr += "<td class='text-center'><a href='" + data[i].file_path + "' target='_blank'><picture><source srcset='/image/pdf.webp' type='image/webp'><img src='/image/pdf.png' alt=''></picture></a></td>";
                } else {
                    htmlStr += "<td class='text-center'><div>N/A</div></td>";
                }
                htmlStr += "</tr>";
            }
            $("#termSheets").empty();
            $("#termSheets").append(htmlStr);
        },
        corporateActionsAppendThead(data, index) {
            if (index == 0 || index == 1 || index == 7) {
                var theadTh = '<th>' + '<div class="boxTh">' + data + '<span id="menu"><span id="top' + index + '" class="bi bi-caret-up-fill" aria-hidden="true"></span><span id="bottom' + index + '" class="bi bi-caret-down-fill" aria-hidden="true"></span><span>' + '</div>' + '</th>'
            } else {
                var theadTh = '<th>' + data + '</th>'
            }
            $('#actionsThead').find('tr').append(theadTh)
            this.CorporateActionsSort(index)
        },
        CorporateActionsSort(index) {
            $('#top' + index).click(() => {
                if (index == 0) {
                    this.CorporateActionsData.sort((x, y) => {
                        if (x[0] < y[0]) {
                            return -1;
                        }
                        if (x[0] > y[0]) {
                            return 1;
                        }
                        return 0;
                    })
                }
                if (index == 1) {
                    this.CorporateActionsData.sort((x, y) => {
                        if (x[1] < y[1]) {
                            return -1;
                        }
                        if (x[1] > y[1]) {
                            return 1;
                        }
                        return 0;
                    })
                }
                if (index == 7) {
                    this.CorporateActionsData.sort((x, y) => {
                        if (x[7] < y[7]) {
                            return -1;
                        }
                        if (x[7] > y[7]) {
                            return 1;
                        }
                        return 0;
                    })
                }
                this.corporateActionsAppendTbody(this.CorporateActionsData);
            })
            $('#bottom' + index).click(() => {
                if (index == 0) {
                    this.CorporateActionsData.sort((x, y) => {
                        if (x[0] > y[0]) {
                            return -1;
                        }
                        if (x[0] < y[0]) {
                            return 1;
                        }
                        return 0;
                    })
                }
                if (index == 1) {
                    this.CorporateActionsData.sort((x, y) => {
                        if (x[1] > y[1]) {
                            return -1;
                        }
                        if (x[1] < y[1]) {
                            return 1;
                        }
                        return 0;
                    })
                }
                if (index == 7) {
                    this.CorporateActionsData.sort((x, y) => {
                        if (x[7] > y[7]) {
                            return -1;
                        }
                        if (x[7] < y[7]) {
                            return 1;
                        }
                        return 0;
                    })
                }
                this.corporateActionsAppendTbody(this.CorporateActionsData);
            })
            
        },
        corporateActionsAppendTbody(arr) {
            var aTbody = '', _this = this
            for (var d = 0, max = arr.length; d < max; d++) {
                if (d === 0) {
                    aTbody += '<tr class="text-center text-nowrap">'
                } else {
                    aTbody += '<tr class="text-center">'
                }
                $.each(arr[d], function (v, i) {
                    if (v == 0) {
                        if (i !== '') {
                            var isRicExist = false;
                            var theRic = '';
                            $.each(this.ric_data, function (index, datas) {
                                if (datas.dsply_nmll == i) {
                                    isRicExist = true;
                                    theRic = datas.ric;
                                }
                            });
                            if (isRicExist) {
                                aTbody += "<td class='text-left'><a href='javascript:getRicToDetails(\"" + theRic + "\"," + " \"" + i + "\")'>" + i + "</a></td>";
                            } else {
                                aTbody += '<td class="text-left">' + i + '</a></td>'
                            }

                        } else {
                            aTbody += '<td class="text-left">-</td>'
                        }
                    } else if (v == 1) {
                        if (i !== '') {
                            aTbody += '<td class="text-left">' + i + '</td>' //暂时未知用哪个做判断
                        } else {
                            aTbody += '<td class="text-left">-</td>'
                        }
                    } else {
                        if (v < 10) {
                            if (i !== '') {
                                if (v == 3 || v == 5) {
                                    aTbody += '<td>' + i.split(' ')[0] + ' ' + _this.$js.formatNumber(i.split(' ')[1], 0, 1) + '</td>'
                                } else {
                                    aTbody += '<td>' + i + '</td>'
                                }

                            } else {
                                aTbody += '<td></td>'
                            }
                        } else {
                            if (i === 'N/A') {
                                aTbody += '<td>' + i + '</td>'
                            } else {
                                aTbody += '<td><a href="' + i + '" target="_blank">click here</a></td>'
                            }
                        }

                    }
                })
                aTbody += '</tr>'
            }
            $("#actionsTbody").empty();
            $('#actionsTbody').append(aTbody)
        },
        searchSymbol() {
            let that = this;
            $("html").click(function (event) {
                if ($(event.target).parents("#menu").length == 0)
                    $("#menu").css({
                        'display': 'none'
                    })
            });
            $('#ricValue').click(function () {
                $("#menu").css({
                    'display': 'none'
                })
            })
            $('#ricValue').bind('input propertychange', function () {
                var compareKey = $(this).val();
                var resetIndex = -1;
                var arr2 = []
                $("#menu").empty();
                var html = '';
                $.each(that.TermSheetsData, (index, comment) => {
                    var copyKey = comment['dsply_nmll'] + ' (' + comment['ticker'] + ")";
                    if (copyKey.toUpperCase().indexOf(compareKey.toUpperCase()) >= 0) {
                        resetIndex += 1;
                        html = '<div class="dropdown-item  text_center docresult-row">' + copyKey + '</div>';
                        $("#menu").append(html);
                        that.ifFocus = true
                        arr2.push(comment)
                        that.ricSelectOption(resetIndex, comment, copyKey, arr2); /*显示当前选择项*/
                    }
                });
                if (compareKey) {
                    $("#menu").css({
                        'display': 'inline-block'
                    })
                } else {
                    $("#menu").css({
                        'display': 'none'
                    })
                }
            });
            $('#ricValue').on('focus', function (e) {
                if ($(this).val().length === 0 && that.ifFocus) {
                    that.termSheetsAppend(that.TermSheetsData);
                    that.ifFocus = false
                    $("#menu").css({
                        'display': 'none'
                    })
                }
            })


            $("html").click(function (event) {
                if ($(event.target).parents("#menu3").length == 0)
                    $("#menu3").css({
                        'display': 'none'
                    })
            });
            $('#ricValue3').click(function () {
                $("#menu3").css({
                    'display': 'none'
                })
            })

            $('#ricValue3').bind('input propertychange', function () {
                var compareKey3 = $(this).val();
                var resetIndex3 = -1;
                $("#menu3").empty();
                var html3 = '';
                var record = ''
                $.each(that.CorporateActionsData, (index, comment3) => {
                    var copyKey3 = comment3[0] + ' (' + comment3[1] + ")";
                    if (copyKey3.toUpperCase().indexOf(compareKey3.toUpperCase()) >= 0) {

                        html3 = '<div class="dropdown-item  text_center docresult-row">' + copyKey3 + '</div>';
                        if (record != copyKey3) {
                            $("#menu3").append(html3);
                            resetIndex3 += 1;
                            record = copyKey3
                        }
                        that.ifFocus = true
                        that.ricThead(resetIndex3, comment3, copyKey3); /*显示当前选择项*/

                    }
                });

                if (compareKey3) {
                    $("#menu3").css({
                        'display': 'inline-block'
                    })
                } else {
                    $("#menu3").css({
                        'display': 'none'
                    })
                }
            });
            $('#ricValue3').on('focus', function (e) {
                if ($(this).val().length === 0 && that.ifFocus) {
                    that.corporateActionsAppendTbody(that.CorporateActionsData);
                    that.ifFocus = false
                    $("#menu3").css({
                        'display': 'none'
                    })
                }
            })
            this.$js.clickEliminate('.input-group', true)
        },
        ricSelectOption(index, data, ricStr, arr) {
            let cLisrArr

            $('#menu div:eq(' + index + ')').click(() => {
                $.each($('#menu div'), (i, c) => {
                    cLisrArr = arr.filter((item) => {
                        return $(c).text().indexOf(item['dsply_nmll'] + ' (' + item['ticker'] + ")") >= 0
                    })
                })
                // console.log(cLisrArr.length);
                $("#ricValue").val(ricStr);
                if (cLisrArr.length > 1) {
                    this.termSheetsAppend(cLisrArr);
                } else {
                    this.termSheetsAppend([data]);
                }

                $("#menu").css({
                    'display': 'none'
                })
            });
        },
        ricThead(index, data, ricStr) {
            // let cLisrArr
            let _this = this
            $('#menu3 div:eq(' + index + ')').click(() => {
                let resultData = [];
                for (var i = 0; i < _this.CorporateActionsData.length; i++) {
                    if (data[0] == _this.CorporateActionsData[i][0] && data[1] == _this.CorporateActionsData[i][1]) {
                        resultData.push(_this.CorporateActionsData[i]);
                    }
                }
                this.corporateActionsAppendTbody(resultData)
                $("#ricValue3").val(ricStr);
                $("#menu3").css({
                    'display': 'none'
                })
            });
        },
        getRicToDetails(ric, dsplay_nmll) {
            //location.href = "warrantdetails?dsplay_nmll=" + dsplay_nmll;
            var routes = this.$router.resolve({
                path: "/id/WarrantDetails",
                query: { dsplay_nmll: dsplay_nmll }
            });
            location.href = routes.href;
        },
        headAhidde: function (html) {
            $(".moveHmove").empty();
            $(".moveHmove").append(
                $("." + html)
                    .find("table")
                    .clone()
            );
            if ($(document).scrollTop() >= 289) {
                $(".moveHmove").show()
            } else {
                $(".moveHmove").hide()
            }
            $(window).scroll(function () {
                if ($(document).scrollTop() >= 289) {
                    $(".moveHmove").show()
                } else {
                    $(".moveHmove").hide()
                }
            })
            $(".moveHmove").find('table').css({
                'transform': 'translateX(-' + $('.' + html).scrollLeft() + 'px)'
            })
            $('.' + html).scroll(function () {
                $(".moveHmove").find('table').css({
                    'transform': 'translateX(-' + $('.' + html).scrollLeft() + 'px)'
                })
            })
        },
        arrowsAhidden: function (html) {
            let scrollLeft
            $('.document_arrows_right').css({
                'transform': 'translateX(' + $('#pills-tabContent').width() + 'px)'
            })
            $(".moveHmove").css({
                'width': $('#pills-tabContent').width(),
                'left': $('#pills-tabContent').offset().left
            })
            if ($('.' + html).css('overflow-x') == 'auto') {
                $('.document_arrows').show()
            } else {
                if (html == 'table_box3') {
                    $(".moveHmove").css({
                        'width': 'unset',
                    })
                }
                $('.document_arrows').hide()
            }

            $(window).resize(function () {
                $('.document_arrows_right').css({
                    'transform': 'translateX(' + $('#pills-tabContent').width() + 'px)'
                })
                $(".moveHmove").css({
                    'width': $('#pills-tabContent').width(),
                    'left': $('#pills-tabContent').offset().left
                })
                if ($('.' + html).css('overflow-x') == 'auto') {
                    $('.document_arrows').show()
                } else {
                    if (html == 'table_box3') {
                        $(".moveHmove").css({
                            'width': 'unset',
                        })
                    }
                    $('.document_arrows').hide()
                }
            })
            $('.document_arrows_left').off("click").on("click", function () {
                scrollLeft = $('.' + html).scrollLeft()
                $('.' + html).scrollLeft(scrollLeft - 10)
                $(".moveHmove").find('table').css({
                    'transform': 'translateX(-' + $('.' + html).scrollLeft() + 'px)'
                })
            })
            $('.document_arrows_right').off("click").on("click", function () {
                scrollLeft = $('.' + html).scrollLeft();
                $('.' + html).scrollLeft(scrollLeft + 10);
                $(".moveHmove").find('table').css({
                    'transform': 'translateX(-' + $('.' + html).scrollLeft() + 'px)'
                })
            })
        }
    }
}